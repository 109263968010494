<template>
  <LiefengContent>
    <template #title>有呼必应工单管理
      <span class="eye" @click="changeEye">
        <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
      </span>
    </template>
    <template #toolsbarRight>
      <Form
        :model="searchForm"
        :label-width="50"
        inline
        label-colon
        class="searchForm"
      >
        <FormItem class="formitem" >
          <span v-solt="label">姓名：</span>
          <Input v-model.trim="searchForm.name" style="width: 150px"></Input>
        </FormItem>
        <FormItem class="formitem">
          <span v-solt="label">手机号：</span>
          <Input v-model.trim="searchForm.mobile" style="width: 150px"></Input>
        </FormItem>
        <FormItem class="formitem">
          <span v-solt="label">类型：</span>
          <Select
            transfer
            v-model="searchForm.itemName"
            style="width: 150px; text-align: left"
            clearable
          >
            <Option
              :value="item"
              v-for="(item, index) in itemNameList"
              :key="index"
              >{{ item }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="社区">
          <Cascader
            transfer
            style="width: 150px"
            v-model="searchForm.dataScopeId"
            :data="communityList"
            :load-data="loadCommunity"
          ></Cascader>
        </FormItem>
        <Button
          type="primary"
          icon="ios-search"
          @click="search"
           style="margin: 0 6px 0 3px"
          >查询</Button
        >
        <Button
          type="success"
          style="margin-right: 6px"
          @click="reset"
          icon="ios-refresh"
          >重置</Button
        >
        <Button
          type="error"
          icon="ios-arrow-back"
          @click="
            $router.push({
              path: '/spokenlangindex',
              query: { menuId: $route.query.menuId },
            })
          "
          >返回</Button
        >
      </Form>
    </template>
    <template #contentArea>
      <Tabs
        v-model="status"
        @on-click="taggleTab"
        :animated="false"
        type="card"
      >
        <TabPane :label="label1" name="1"></TabPane>
        <TabPane :label="label2" name="2"></TabPane>
        <TabPane :label="label3" name="3"></TabPane>
      </Tabs>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :loading="loading"
        :height="tableHeight"
        :curPage="page"
        :total="total"
        :pagesizeOpts="[20, 30, 50, 100]"
        :page-size="pageSize"
        @hadlePageSize="hadlePageSize"
      ></LiefengTable>
       <!-- 查看详情 -->
      <LiefengModal
        title="查看详情"
        :value="seeAnswerStatus"
        @input="seeAnswerStatusFn"
        :fullscreen="true"
      >
        <template #contentarea>
          <Detail
            ref="detail"
            @search="search"
            :detailData="detailData"
            :seeAnswerTip="seeAnswerTip"
            v-if="seeAnswerStatus"
          ></Detail>
        </template>
        <template #toolsbar>
          <Button
            type="primary"
            @click="seeAnswerStatusFn(false)"
            > 关闭</Button
          >
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/spokenlangmanage')
import LiefengContent from "@/components/LiefengContent3";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
import Detail from '@/views/spokenlang/childrens/Detail'
export default {
  data() {
    return {
      label1: (h) => {
        return h("div", [
          h(
            "span",
            {
              style: {
                marginRight: "4px",
              },
            },
            "处理中"
          ),
          h("Badge", {
            props: {
              count: this.tabsNumObj.handlingNum,
            },
          }),
        ]);
      },
      label2: (h) => {
        return h("div", [
          h(
            "span",
            {
              style: {
                marginRight: "4px",
              },
            },
            "处理成功"
          ),
          h("Badge", {
            props: {
              count: this.tabsNumObj.successNum,
            },
          }),
        ]);
      },
      label3: (h) => {
        return h("div", [
          h(
            "span",
            {
              style: {
                marginRight: "4px",
              },
            },
            "处理失败"
          ),
          h("Badge", {
            props: {
              count: this.tabsNumObj.failNum,
            },
          }),
        ]);
      },
      // 脱敏
      hasSensitive:true,
      searchForm: {},
      itemNameList: [],
      talbeColumns: [
        {
          title: "社区",
          key: "communityName",
          width: 140,
          align: "center",
        },
        {
          title: "姓名",
          key: "name",
          width: 140,
          align: "center",
        },
        {
          title: "性别",
          key: "sex",
          width: 140,
          align: "center",
        },
        {
          title: "手机号",
          key: "mobile",
          width: 140,
          align: "center",
        },
        {
          title: "标题",
          key: "title",
          minWidth: 140,
          align: "center",
        },
        {
          title: "类型",
          key: "itemName",
          width: 160,
          align: "center",
        },
        {
          title: "状态",
          key: "status",
          width: 140,
          align: "center",
        },
        {
          title: "操作",
          width: 120,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                    type: "warning",
                  },
                  style: {
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                      this.seeAnswerTip = "seeManage";
                      this.getDetailData(params.row.id);
                    },
                  },
                },
                "查看详情"
              ),
            ]);
          },
        },
      ],
      tableData: [],
      loading: false,
      tableHeight: "",
      page: 1,
      total: 0,
      pageSize: 20,
      status: "1",
      communityList: [],
      tabsNumObj: {},

      // 查看，回复详情
      detailData: {},
      seeAnswerStatus: false,
      submitLoading: false,
      statTyleList: []
    };
  },
  methods: {
    changeEye(){
      this.hasSensitive = !this.hasSensitive
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize,
      });
    },
    seeAnswerStatusFn(status) {
      this.seeAnswerStatus = status;
    },
    // 获取类型字典
    getStatTyleList() {
     return new Promise(resolve => {
       this.$get("/voteapi/api/common/sys/findDictByDictType", {
      dictType: "EVENT_ISSUE_2",
    }).then((res) => {
      if (res.code == 200) {
        resolve(res.dataList);
      }
    });
     })
    },
    // 根据id获取详情
    getDetailData(id) {
      this.$Message.destroy();
      this.$Message.loading({
        durction: 0,
        content: "正在获取详情信息，请稍等...",
      });
      this.$get("/info/api/pc/eventIssue/getEventRespondDetail", { id })
        .then(async (res) => {
          if (res.code == 200 && res.data) {
            if(!this.statTyleList || this.statTyleList.length == 0) {
              this.statTyleList =  await this.getStatTyleList();
            }
            this.$Message.destroy();
            let photo = res.data.photo.split(",");
            if (photo.length > 0) {
              res.data.photo = photo.map((item, index) => {
                return {
                  url: item,
                };
              });
            }
            if (!res.data.eventIssueReplyDetailVo) {
              res.data.eventIssueReplyDetailVo = {};
            }
            if(res.data.statType) {
              this.statTyleList.map(item => {
                if(item.dictKey == res.data.statType) {
                  res.data.statType = item.dictValue;
                }
              })
            }
            this.detailData = res.data;
            this.seeAnswerStatus = true;
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.destroy();
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
    // 获取区
    getPulishData(code, list) {
      this.$get("/datamsg/api/pc/staff/selectCascadeDataScope", {
        orgCode: code,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      }).then((res) => {
        if (res.code == 200) {
          this[list] = res.dataList.map((item) => {
            return {
              value: item.orgPath,
              label: item.orgName,
              orgLevel: item.orgLevel,
              loading: false,
              children: [],
            };
          });
        }
      });
    },
    // 动态加载街道/社区
    loadCommunity(item, callback) {
      item.loading = true;
      let arrCode = item.value.split("-");
      this.$get("/datamsg/api/pc/staff/selectCascadeDataScope", {
        orgCode: arrCode[arrCode.length - 1],
        oemCode: parent.vue.oemInfo.oemCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      }).then((res) => {
        if (res.code == 200) {
          if (!item.index) {
            item.children = res.dataList.map((item) => {
              return {
                value: item.orgPath,
                label: item.orgName,
                orgLevel: item.orgLevel,
                loading: false,
                children: [],
                index: 1,
              };
            });
          } else {
            item.children = res.dataList.map((item) => {
              return {
                value: item.orgPath,
                label: item.orgName,
                orgLevel: item.orgLevel,
                index: 1,
              };
            });
          }
          item.loading = false;
          callback();
        }
      });
    },
    taggleTab() {
      this.searchForm = {};
      this.hadlePageSize({
        pageSize: this.pageSize,
        page: 1,
      });
    },
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize,
      });
    },

    reset() {
      this.searchForm = {};
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize,
      });
    },
    hadlePageSize(obj) {
      this.loading = true;
      //   获取状态数量
      this.$get("/info/api/pc/eventIssue/getRespondStatusNum", {
        status: this.status,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        // orgCode: "SQJWH0002",
        oemCode: parent.vue.oemInfo.oemCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        ...this.searchForm,
        communityCode:
          this.searchForm.communityCode &&
          this.searchForm.communityCode.length > 0
            ? this.searchForm.communityCode[
                this.searchForm.communityCode.length - 1
              ].split("-")[
                this.searchForm.communityCode[
                  this.searchForm.communityCode.length - 1
                ].split("-").length - 1
              ]
            : "",
      })
        .then((res) => {
          if (res.code == 200 && res.data) {
            this.tabsNumObj = res.data;
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.destroy();
          console.log(err);
          this.loading = false;
          this.$Message.error({
            background: true,
            content: "获取数据失败，请重试",
          });
        });
      this.$get("/info/api/pc/eventIssue/queryEventIssueRespondPage", {
        hasSensitive:this.hasSensitive,
        status: this.status,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        // orgCode: "SQJWH0002",
        oemCode: parent.vue.oemInfo.oemCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        ...this.searchForm,
        communityCode:
          this.searchForm.communityCode &&
          this.searchForm.communityCode.length > 0
            ? this.searchForm.communityCode[
                this.searchForm.communityCode.length - 1
              ].split("-")[
                this.searchForm.communityCode[
                  this.searchForm.communityCode.length - 1
                ].split("-").length - 1
              ]
            : "",
        page: obj.page,
        pageSize: obj.pageSize,
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 200 && res.dataList) {
            res.dataList.map((item) => {
              item.status =
                item.status == "1"
                  ? "处理中"
                  : item.status == "2"
                  ? "处理成功"
                    : item.status == "3"
                    ? "处理失败"
                  : "";
                  item.sex = item.sex == '1' ? '男' : item.sex == '2' ? '女' : '';
            });
            this.tableData = res.dataList;
            this.page = res.currentPage;
            this.pageSize = res.pageSize;
            this.total = res.maxCount;
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.destroy();
          console.log(err);
          this.loading = false;
          this.$Message.error({
            background: true,
            content: "获取数据失败，请重试",
          });
        });
    },
  },
  async created() {
    this.tableHeight = window.innerHeight - 170 + "";
    // 获取广州市的区
    this.getPulishData(4401, "communityList");
    // 获取类型字典
    await this.$get("/info/api/pc/eventIssue/getItemNameList", {
      status: this.status,
      orgCode: parent.vue.loginInfo.userinfo.orgCode,
      oemCode: parent.vue.oemInfo.oemCode,
      custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      ...this.searchForm,
      communityCode:
        this.searchForm.communityCode &&
        this.searchForm.communityCode.length > 0
          ? this.searchForm.communityCode[
              this.searchForm.communityCode.length - 1
            ].split("-")[
              this.searchForm.communityCode[
                this.searchForm.communityCode.length - 1
              ].split("-").length - 1
            ]
          : "",
    }).then((res) => {
      if (res.code == 200) {
        this.itemNameList = res.dataList;
      }
    });
    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
    Detail
  },
};
</script>
    
<style scoped lang='less'>
// /deep/#toolsbarRight {
  // width: 100%;
  // .searchForm {
  //   width: 100%;
  //    text-align: right;
  //   .ivu-form-item {
  //     margin-bottom: 0;
  //      margin-right: 4px;
  //   }
  
  // }
  
// }
// /deep/.ivu-form-item-content{
//     margin-left: 0px !important;
//     &:last-child{
//       margin-left: 50px !important;
//     }
//   }
  .formitem{
    /deep/.ivu-form-item-content{
      margin-left: 0px !important;
    }
  }
  .eye{
    cursor: pointer;
    display: inline-block;
    font-size: 17px;
}
</style>